<template>
    <div class='card'>
        <div class='card-body'>
            <GChart type="LineChart" :data='chartData' :options='optionsValues'/>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('Stats/Subscriptions');

import { GChart } from "vue-google-charts";
    export default {
        components: {
            GChart
        },
        data(){
            return {
                optionsValues: {
                    title: 'Nuove Subscriptions per giorno',
                    height: 600
                }
            }
        },
        computed: {
            ...mapGetters(['rows', 'loading', 'from', 'to', 'providers']),
            chartData() {
                const data = [
                    ['Giorno', 'Totale'],
                ]
                if (this.rows){
                    this.rows.forEach(row => {
                        const d = new Date(Date.parse(row['date']));
                        const l = [d, row['created']];
                        data.push(l);
                    })
                }
                return data;
            }
        },
        methods: {}
    }
</script>