<template>
    <div class='grid'>
        <div class='col-12'>
            <div class='card'>
                <Toolbar>
                    <template #start>
                        <div class='grid'>
                            <div class='col-4'>
                                <div class='p-inputgroup'>
                                    <Dropdown v-model="selected_chart"
                                              id='chart_id'
                                              dataKey="id"
                                              :options="available_charts"
                                              optionLabel="label"
                                              option-value='id'
                                    />
                                </div>
                            </div>
                            <div class='col-4'>
                                <Calendar
                                    :showTime='false'
                                    inputId='from_date'
                                    v-model='fromFilter'
                                    dateFormat='dd/mm/yy'
                                />
                            </div>
                            <div class='col-4'>
                                <Calendar
                                    :showTime='false'
                                    inputId='to_date'
                                    v-model='toFilter'
                                    dateFormat='dd/mm/yy'
                                />
                            </div>
                        </div>
                    </template>

                    <template #end>
                        <ButtonRefresh @refresh='refreshData' />
                    </template>
                </Toolbar>
            </div>
        </div>
        <div class='col-12'>
            <ActiveSubsXPL title='Subscriptions Annuali Attive' :sub_data='yearly_subscriptions' v-if='selected_chart==="yearly_subscriptions"'/>
            <ActiveSubsXPL title='Subscriptions Mensili Attive' :sub_data='monthly_subscriptions' v-if='selected_chart==="monthly_subscriptions"'/>
            <ActiveSubsXPL title='Subscriptions Trimestrali Attive' :sub_data='tri_monthly_subscriptions' v-if='selected_chart==="monthly_3_subscriptions"'/>
            <ActiveSubsXPL title='Subscriptions Semestrali Attive' :sub_data='six_monthly_subscriptions' v-if='selected_chart==="monthly_6_subscriptions"'/>
            <NewSubs v-if='selected_chart==="new_subscriptions"'/>
            <ActiveSubs v-if='selected_chart==="active_subscriptions"'/>
            <FreeTrials v-if='selected_chart==="free_trials"'/>
            <ActiveSubsXPR title='Subscriptions Manuali' :sub_data='manual_subscriptions'  v-if='selected_chart==="manual_subs"'/>
            <ActiveSubsXPR title='Subscriptions Chargebee' :sub_data='web_subscriptions'  v-if='selected_chart==="web_subs"'/>
            <ActiveSubsXPR title='Subscriptions Apple / Ios' :sub_data='ios_subscriptions'  v-if='selected_chart==="ios_subs"'/>
            <ActiveSubsXPR title='Subscriptions Google / Android' :sub_data='android_subscriptions'  v-if='selected_chart==="android_subs"'/>
            <ActiveSubsXPR title='Subscriptions Slashers' :sub_data='slashers_subscriptions'  v-if='selected_chart==="slashers_subs"'/>
            <ActiveSubsXPR title='Subscriptions Corporate / B2B' :sub_data='corporate_subscriptions'  v-if='selected_chart==="corporate_subs"'/>
        </div>
    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import ButtonRefresh from '../../../../components/4books/ButtonRefresh.vue';

const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('Stats/Subscriptions');
import Notifications from '../../../../mixins/notifications';
import ActiveSubs from './ActiveSubs.vue';
import FreeTrials from './FreeTrials.vue';
import NewSubs from './NewSubs.vue';
import ActiveSubsXPL from './ActiveSubsXPL.vue';
import ActiveSubsXPR from './ActiveSubsXPR.vue';
export default {
    data(){
        return {
            available_charts: [
                {id: "yearly_subscriptions", label: "Subscriptions Annuali"},
                {id: "monthly_subscriptions", label: "Subscriptions Mensili"},
                {id: "active_subscriptions", label: "Subscriptions Attive"},
                {id: "new_subscriptions", label: "Nuove Subscriptions"},
                {id: "monthly_3_subscriptions", label: "Subscription Trimestrali"},
                {id: "monthly_6_subscriptions", label: "Subscription Semestrali"},
                {id: "free_trials", label: "Free Trials"},
                {id: "manual_subs", label: "Provider Manual"},
                {id: "web_subs", label: "Provider Chargebee"},
                {id: "ios_subs", label: "Provider Apple"},
                {id: "android_subs", label: "Provider Android"},
                {id: "slashers_subs", label: "Provider Slashers"},
                {id: "corporate_subs", label: "Provider B2B"},
            ],
            selected_chart: "yearly_subscriptions",
        }
    },
    mixins: [Notifications],
    components: { ActiveSubsXPR, ActiveSubsXPL, NewSubs, FreeTrials, ButtonRefresh, ActiveSubs },
    computed: {
        ...mapGetters([
            'rows',
            'loading',
            'from',
            'to',
            'monthly_subscriptions',
            'tri_monthly_subscriptions',
            'six_monthly_subscriptions',
            'yearly_subscriptions',
            'manual_subscriptions',
            'web_subscriptions',
            'ios_subscriptions',
            'android_subscriptions',
            'slashers_subscriptions',
            'corporate_subscriptions'
        ]),
        fromFilter: {
            get() {
                console.log('FROM is', this.from);
                const d = new Date(Date.parse(this.from));
                console.log('PARSED DATE', d);
                return d;
            },
            set(value) {
                this.setFrom(this.toStringDate(value));
            },
        },
        toFilter: {
            get() {
                return new Date(Date.parse(this.to));
            },
            set(value) {
                this.setTo(this.toStringDate(value));
            },
        },
    },
    methods: {
        ...mapActions(['search']),
        ...mapMutations(['setTo', 'setFrom']),
        refreshData() {
            this.search().then().catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        toStringDate(d) {
            return d.toISOString().split('T')[0];
        },
        initDates() {
            let d = new Date();
            if (!this.to) {
                this.setTo(this.toStringDate(d));
            }
            if (!this.from) {
                d.setMonth(d.getMonth() - 1);
                d.setHours(0, 0, 0, 0);
                this.setFrom(this.toStringDate(d));
            }
        },
    },
    created() {
        this.initDates();
    },
    mounted() {
        this.refreshData();
    },
};
</script>
