<template>
    <div class='card'>
        <div class='card-body'>
            <Toolbar>
                <template #center>
                    <Button class='mx-1 p-button-info' label="Tutti" :class='{"p-button-outlined": filters.length !== activeFiltersLength}' @click='activateAll' />
                    <Button
                        class='mx-1 p-button-info'
                        v-for="filter in filters"
                        :key='"filt_"+filter'
                        @click='activeFilters[filter] = !activeFilters[filter]'
                        :label="filter"
                        :class='{"p-button-outlined": !activeFilters[filter]}'  />
                    <Button class='mx-1 p-button-info' label="Nessuno" :class='{"p-button-outlined": activeFiltersLength > 0}' @click='deactivateAll'/>
                </template>
            </Toolbar>
            <GChart type="LineChart" :data='chartData' :options='optionsValues'/>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('Stats/Subscriptions');

import { GChart } from "vue-google-charts";
    export default {
        props: {
            title: {
                type: String,
                required: true
            },
            sub_data: {
                type: Array,
                required: true
            }
        },
        components: {
            GChart
        },
        data(){
            const filters = ['Web', 'Android', 'Ios', 'Manual', 'Slashers', 'Corporate'];
            const active = {};
            filters.forEach(filter => {
                active[filter] = true;
            })
            return {
                filters: filters,
                activeFilters: active,
                optionsValues: {
                    title: this.title,
                    height: 600
                }
            }
        },
        computed: {
            ...mapGetters(['loading', 'from', 'to', 'providers']),
            activeFiltersLength(){
                let len = 0;
                this.filters.forEach(filter => {
                    if (this.activeFilters[filter]) {
                        len++;
                    }
                })
                return len;
            },
            activeFilterArray(){
                const row = [];
                this.filters.forEach(filter => {
                    if (this.activeFilters[filter]) {
                        row.push(filter);
                    }
                })
                return row;
            },
            chartData() {
                const headers = ['Giorno', 'Totale', ...this.activeFilterArray];
                const data = [
                    headers,
                ]
                if (this.sub_data){
                    this.sub_data.forEach(row => {
                        const line = [];
                        headers.forEach(header => {
                            line.push(row[header])
                        })
                        data.push(line);
                    })
                }
                return data;
            }
        },
        methods: {
            deactivateAll(){
                this.filters.forEach(filter => {
                    this.activeFilters[filter] = false;
                })
            },
            activateAll(){
                this.filters.forEach(filter => {
                    this.activeFilters[filter] = true;
                })
            },
        }
    }
</script>
